import { useState } from "react"
import styles from "./Input.module.css"

export const InputText = props => {
  return (
    <label className="block text-sm font-medium leading-6 text-gray-900">
      <span>{props.text}</span>
      <input
        type={props.type}
        text={props.text}
        name={props.name}
        id={props.name}
        value={props.value}
        onChange={props.handleonChange}
        onKeyUp={props.keyUp}
        placeholder={props.placeholder}
        required={props.required}
        step="0.01"
        min="0.01"
        readOnly={props.readOnly}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        disabled={props.disabled}
      />
    </label>
  )
}

export const InputSelect = ({
  text,
  name,
  options,
  handleOnChange,
  onValue,
  required,
  descoptions = "descricao",
}) => {
  return (
    <label className="block text-sm font-medium leading-6 text-gray-900">
      <span>{text}</span>
      <select
        required={required}
        name={name}
        id={name}
        onChange={handleOnChange}
        value={onValue}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      >
        <option value="">--Selecione--</option>
        {options?.map((option, index) => (
          <option value={option.id} key={index}>
            {option.descricao}
          </option>
        ))}
      </select>
    </label>
  )
}

export const InputCheckBox = props => {
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        <input
          name={props.name}
          id={props.name}
          onChange={props.handleonChange}
          checked={props.value}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          
        />
      </div>
      <div className="text-sm leading-6">
        <label htmlFor="comments" className="font-medium text-gray-900">
          {props.text}
        </label>
      </div>
    </div>
  )
}

export const InputRadio = ({ name, handleOnChange, fields, handleValue }) => {
  return (
    <div className={styles.container_radios}>
      {fields.map(field => (
        <label key={field.id}>
          <input
            type="radio"
            name={name}
            id={field.value}
            onChange={handleOnChange}
            value={field.value}
            checked={handleValue === field.value ? true : false}
          />
          <span>{field.text}</span>
        </label>
      ))}
    </div>
  )
}

export const InputSelectWithSearch = ({
  text,
  name,
  options,
  handleOnChange,
  required,
}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredOptions, setFilteredOptions] = useState(options)

  const handleSearch = event => {
    const searchTerm = event.target.value
    setSearchTerm(searchTerm)

    const filteredOptions = options.filter(option =>
      option.descricao.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    setFilteredOptions(filteredOptions)
  }

  return (
    <div className={styles.inputSelect}>
      <fieldset style={{ border: "1px solid", padding: 5 }}>
        <legend>{text}</legend>
        <InputText
          type="search"
          value={searchTerm}
          handleonChange={handleSearch}
          placeholder="Digite para pesquisar"
        />

        <select
          required={required}
          name={name}
          id={name}
          onChange={handleOnChange}
        >
          <option value="">--Selecione uma opção--</option>
          {filteredOptions?.map(option => (
            <option value={option.id} key={option.id}>
              {option.descricao}
            </option>
          ))}
        </select>
      </fieldset>
    </div>
  )
}

export const InputDataList = ({ list, handleChange, name }) => {
  return (
    <div className={styles.inputText}>
      <input
        type="search"
        list="list"
        name={name}
        id={name}
        onChange={handleChange}
      />
      <datalist id="list">
        {list?.map(lst => (
          <option key={lst.id} value={lst.id}>
            {lst.descricao}
          </option>
        ))}
      </datalist>
    </div>
  )
}
