export const css = {
    page:{
      //width:297,
      height:396,
      flexDirection:'column',
      backgroundColor:'#ffffff',
      fontFamily:'Helvetica',      
    },
    tamanho:{
      height:'376mm', 
      width:'396mm'
    },
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    }, 
    tableRow: { 
     
      flexDirection: "row",
    }, 
    tableCol: { 
      width:'auto',
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 ,
     
    }, 
    tableCell: { 
      margin: "auto",    
      fontSize: 10 
    },
    
    footer:{
      position:'absolute',
      height:'50px',
      width:'100%',
      marginTop:'15px',
      padding:'0 15px 0 15px',  
      bottom:0,
      left:0,
      right:0,   
    },
    bodyPdf:{
      padding:'15px', 
     //height:396,
      width:'100%' ,
     
    },
    topPdf:{
      width:'40%',
      textAlign:'center',
      fontSize:'11pt',    
      padding:'8px 0 8px 0',
      backgroundColor:'#1467b6',
      color:'#ffffff'
    },
    viewTopPdf:{
      width:'100%',    
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor: '#434345',
      marginBottom:'10px',
    },
    title:{
      textAlign:'center',
      margin:'5px 0 5px 0'
    },
    titleH2:{    
      fontStyle:'normal',
      fontWeight:'600',
      fontSize:'18pt',
      fontFamily:'Helvetica',
      color:'#000000'
    },
    titleH3:{
      fontStyle:'normal',    
      fontSize:'15pt',
      fontFamily:'Helvetica',
      color:'#000000'
    },
    lineWidth:{
      borderBottom:'3px solid #e3e3e3',
      
    },
    boxInfo:{    
      margin:'5px 0 5px 0',
    },
    headBoxInfo:{
      backgroundColor:'#e3e3e3',
      fontWeight:'extrabold', 
      fontSize:'12pt',
      display:'flex',
      flexDirection:'row',
      alignItems:'center'
    },
    bodyBoxInfo:{    
      border:'3px solid #e3e3e3',
      marginBottom:'10px'
    },
    containerBody:{   
      margin:'15px',     
    },
    fontSizeBoxInfo:{
      fontSize:'11pt',
      padding:'2px 0 2px 0'
    },
    boxInfoBeneficio:{
      width:'100%',
      display:'flex',
      flexDirection:'row'
    },
    columnInfoBenef1:{width:'50%'},
    columnInfoBenef2:{width:'50%'},
  
  
    headTable:{ 
      width:'120px',
      height:'20px',   
      fontSize:'11pt',
      fontWeight:'bold',
      border:'1px solid black',
      textAlign:'center',
      padding:'2px 5px 2px 5px',
      backgroundColor:'rgb(220 220 220)'
    },
  
    tableMargemEmpr:{
      width:'100%',
      minHeight:'20%',
      display:'flex', 
      flexDirection:'row',
      justifyContent:'center',
  
      
        
    },
    column1:{
      width:'200px',
      borderLeft:'1px solid black',
      borderRight:'1px solid black',
      backgroundColor:'#dcdcdc'
    },
    column1Row:{
      borderBottom:'1px solid black',
      padding:'3px',
      height:'20px', 
      fontSize:'10px',
      
    },
    column2:{
      width:'180px',
      borderLeft:'1px solid black',
      borderRight:'1px solid black',
      backgroundColor:'#dcdcdc'
    },
    column2Row:{       
      borderBottom:'1px solid black',
      padding:'3px',
      height:'20px', 
      fontSize:'10px',   
    },
    column3:{},
    column4:{},
  
    columnTable:{
      width:'30%',
    },
    lineTable:{
      fontSize:'11pt',
    },
    
  }