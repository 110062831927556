import { useEffect, useState } from "react"
import { ExtratoOfflinePdf } from "../../Reports/Offline"
import { Buttons } from "../../components/Button"
import { FormModal } from "../../components/FormModal"
import { InputSelect, InputText } from "../../components/Inputs"
import { getObterRequisicao, refazerConsulta } from "../../functions/In100"
import {
  ApiConsultaOffline,
  ListarOfflineIn100,
} from "../../functions/consulta_offline"
import {
  ConsultarExtratoOnline,
  RefazerConsultaOnline,
} from "../../functions/consulta_online"
import styles from "./In100.module.css"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { ExtratoIn100Pdf } from "../../Reports/In100/ExtratoIn100Pdf"
import { Alert, AlertDialog } from "../../components/AlertDialog"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { MyPDFComponent } from "../../components/PdfExtratoOnline/ReactToPdf"
import { IAlert } from "../../interfaces/Alert"
import { IIn100 } from "../../interfaces/In100"
import { ValidarCpf } from "../../utils/CpfValidate"
import { cpfMask } from "../../utils/cpfMask"

export const In100 = () => {
  const [modalIn100, setModalIn100] = useState(false)
  const [modalOffLine, setModalOffline] = useState(false)
  const [alert, setAlert] = useState({ visibled: false, text: "" })
  const [tipoconsulta, setTipoConsulta] = useState(1)
  const [in100, setIn100] = useState(IIn100)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [cpf, setCpf] = useState("")
  const [beneficio, setBeneficio] = useState("")
  const [lista_offline, setLista_Offline] = useState([])
  const [placeholder, setPlaceHolder] = useState("Digite o número do CPF")
  const [arrayPages, setArrayPages] = useState([])
  const [qPages, setQPages] = useState(0)
  const [resultOff, setResultOff] = useState({})
  const [totalRegistros, setTotalRegistros] = useState(0)
  const [campoCpfRepresentante, setCampoCpfRepresentante] = useState(false)
  const [cpfRepresentante, setCpfRepresentante] = useState({ cpfR: "" })
  const [formRepresentante, setFormRepresentante] = useState(false)

  const [selectIn100, setSelectIn100] = useState(true)
  const [selectIn100RepLegal, setSelectIn100RepLegal] = useState(false)
  const [solicitarIn100, setSolicitarIn100] = useState({
    tiposervico: 4,
    idConsultaOff: 0,
    cpf: "",
    beneficio: "",
    cpfRepresentanteLegal: "",
  })
  const [dialogIn100, setDialogIn100] = useState(IAlert)
  const [dialogOnline, setDialogOnline] = useState(IAlert)
  const [refConsulta, setRefConsulta] = useState(false)
  const queryClient = useQueryClient()
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })
  useEffect(() => {
    setLoading(true)
  }, [])

  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ["in100Data", currentPage],
    async () => {
      const resp = await ListarOfflineIn100(currentPage)
      return resp.data

      //setLista_Offline(resp.data.items)

      // setPagination(resp.data.pagination)
      //setTotalRegistros(resp.data.items)
      //setLoading(false)
    },
    {
      retry: 3,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      //keepPreviousData:true,
      staleTime: 5000,
      onSuccess: data => {
        console.log(data.items)
        setLista_Offline(data.items)
        setPagination(data.pagination)
        setLoading(false)
      },
      onError: error => {
        console.error(error)
        setLoading(false)
      },
    },
  )

  const refactorIn100 = useMutation(refazerConsulta, {
    onSuccess: () => {
      refetch()
      setLoading(false)
      setCpfRepresentante("")
      setResultOff({})
    },
    onError: error => {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response ? error.response?.data.message : "Network Error",
      })
    },
  })

  const solicIn100 = useMutation(getObterRequisicao, {
    onSuccess: () => {
      refetch()
      //setLoading(false)
    },
    onError: error => {
      console.error(error.response)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response?.data.message,
      })
    },
  })

  const solicOffline = useMutation(ApiConsultaOffline, {
    onSuccess: () => {
      refetch()
      //setLoading(false)
    },
    onError: error => {
      console.error(error.response)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response
          ? error.response?.data.message
          : "Erro ao realizar consulta",
      })
    },
  })

  const solicOnline = useMutation(ConsultarExtratoOnline, {
    onSuccess: response => {
      refetch()
      setAlert({
        visibled: true,
        text: response.data.message,
      })
      //setShowLoading(false)
    },
    onError: error => {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response?.data.message || "Ocorreu um erro desconhecido",
      })
    },
  })

  const refazerSolicOn = useMutation(RefazerConsultaOnline, {
    onSuccess: () => {
      refetch()
      setLoading(false)
      console.log(refazerSolicOn.data)
    },
    onError: error => {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response
          ? error.response?.data.message
          : "Erro ao realizar consulta",
      })
    },
  })

  if (error) {
    console.error(error?.response)
    return error?.response?.data.message
  }

  const consultaOffline = async () => {
    setLoading(true)

    let sCpf = cpf
      .replaceAll(".", "")
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .trim()
    let sBeneficio = beneficio
      .replaceAll(".", "")
      .replaceAll("-", "")
      .replaceAll(" ", "")
      .trim()

    if (tipoconsulta === 1) {
      let valCpf = ValidarCpf(cpf)

      if (!valCpf) {
        setLoading(false)
        return setAlert({
          visibled: true,
          text: `${sCpf} Cpf Inválido`,
        })
      }
    }

    await solicOffline.mutate({ cpf: sCpf, beneficio: sBeneficio })
    setCpf("")
    setBeneficio("")
  }

  const handlePdf = dados => {
    // ComprovanteIn100(data)
    ExtratoIn100Pdf(dados)
  }

  const handlePdfOffLine = dados => {
    ExtratoOfflinePdf(dados)
  }

  const handleDetalheOffline = async dados => {
    setModalOffline(true)
    setResultOff(dados)
  }

  const handleObterRequisicao = async req => {
    setResultOff(req)
    setSolicitarIn100({
      idConsultaOff: req.id_extrato_off,
      cpf: req.cpf,
      beneficio: req.beneficio,
    })

    setFormRepresentante(true)
  }

  const handleCpf = e => {
    setCpfRepresentante({ cpfR: cpfMask(e.target.value) })
  }

  const handleExtratoOnlinepdf = async off => {
    setLoading(true)
    console.log(off)
    switch (parseInt(off.statusExtratoOn)) {
      case 0:
        solicOnline.mutate({
          beneficio: off.beneficio,
          idExtratoOff: off.id_extrato_off,
        })
        break
      case 1:
        setAlert({
          visibled: true,
          text: off.descStatusExtratoOn,
        })
        setLoading(false)
        break
      case 2:
        await MyPDFComponent(off.resultExtratoOn)
        setLoading(false)
        break
      default:
        break
    }
  }

  const handleInputSelectChange = e => {
    setCpf("")
    setBeneficio("")

    if (parseInt(e.target.value) === 1) {
      setPlaceHolder("Digite o número do CPF")
    } else if (parseInt(e.target.value) === 2) {
      setPlaceHolder("Digite o número do Beneficio")
    }

    setTipoConsulta(e.target.value === "" ? 1 : e.target.value)
  }

  const handleInputChange = e => {
    if (parseInt(tipoconsulta) === 1) {
      setCpf(cpfMask(e.target.value))
    } else if (parseInt(tipoconsulta) === 2) {
      setBeneficio(e.target.value)
    }
  }

  const ConsultarIn100 = async () => {
    if (selectIn100RepLegal && cpfRepresentante.cpfR === "") {
      return setAlert({
        visibled: true,
        text: "Informe o número de CPF do representante legal",
      })
    }

    try {
      const cpfRep = cpfRepresentante.cpfR
        ?.replaceAll(".", "")
        .replaceAll("-", "")

      setSolicitarIn100({ cpfRepresentanteLegal: cpfRep })
      if (refConsulta) {
        await refactorIn100.mutate({ id: resultOff.idIn100, cpfR: cpfRep })
      } else {
        await solicIn100.mutate(solicitarIn100)
      }

      setFormRepresentante(false)
      setLoading(true)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: `${error.response?.status} | ${error.response?.data.message}`,
      })
    }
  }

  const handleAlertDialog = dados => {
    setResultOff(dados)
    setRefConsulta(true)
    setDialogIn100({ message: "Refazer Consulta da in100 ? ", visibled: true })
  }

  const RefazerConsultaExtratoOnline = async d => {
    try {
      if (d) {
        setLoading(true)
        await refazerSolicOn.mutate({
          beneficio: resultOff.beneficio,
          id: resultOff.idExtratoOn,
        })
      }
      setDialogOnline({})
    } catch (error) {
      console.error(error)
    }
  }

  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  return (
    <div className={styles.container_in100}>
      {loading && <Loading />}
      {alert.visibled && (
        <Alert
          text={alert.text}
          onDialog={() => setAlert({ visibled: false, text: "" })}
        />
      )}

      {dialogIn100.visibled && (
        <AlertDialog
          text={dialogIn100.message}
          onDialog={d => {
            if (d) {
              setFormRepresentante(true)
              setDialogIn100(false)
            }
            setDialogIn100({})
          }}
        />
      )}

      {dialogOnline.visibled && (
        <AlertDialog
          text={dialogOnline.message}
          onDialog={d => RefazerConsultaExtratoOnline(d)}
        />
      )}

      <div className={styles.parametro_pesquisa}>
        <fieldset>
          <legend>Parâmetro de pesquisa</legend>

          <div className={styles.container_inputs}>
            <div className={styles.inputSelec_CpfBen}>
              <InputSelect
                options={[
                  { id: 1, descricao: "CPF" },
                  { id: 2, descricao: "Nº Beneficio" },
                ]}
                handleOnChange={e => handleInputSelectChange(e)}
                onValue={tipoconsulta}
                required={true}
              />
            </div>

            <div className={styles.inputText_CpfBen}>
              <InputText
                placeholder={placeholder}
                handleonChange={e => handleInputChange(e)}
                value={parseInt(tipoconsulta) === 1 ? cpf : beneficio}
              />
            </div>

            <Buttons text="Pesquisar" onClick={() => consultaOffline()} />
          </div>
        </fieldset>

        {isLoading && <strong>Carregando...</strong>}
      </div>

      <div className={styles.table_in100}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white-700 uppercase bg-dark-50 dark:text-white">
            <tr>
              <th scope="col" className="px-3 py-3">
                CPF
              </th>
              <th scope="col" className="px-3 py-3">
                Nº do benefício
              </th>
              <th scope="col" className="px-3 py-3">
                Nome do beneficiário
              </th>
              <th scope="col" className="px-3 py-3">
                Espécie
              </th>
              <th scope="col" className="px-3 py-3">
                Data IN100
              </th>
              <th scope="col" className={`${styles.acoes} px-3 py-3`}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {lista_offline &&
              lista_offline.map((offline, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-3 py-2">{offline.cpf}</td>
                  <td className="px-3 py-2">{offline.beneficio}</td>
                  <td className="px-3 py-2">
                    {offline.resultado.Beneficiario?.Nome}
                  </td>
                  <td className="px-3 py-2">
                    {offline.resultado.Beneficiario?.DescricaoEspecie}
                  </td>
                  <td className="px-3 py-2">
                    <div
                      title={`Ultima atualização : ${new Date(
                        offline.updatedAt,
                      ).toLocaleString()}`}
                    >
                      {offline.createdAt != null
                        ? new Date(offline.createdAt).toLocaleString()
                        : ""}
                    </div>
                  </td>
                  <td className={styles.acoes}>
                    <button
                      title="Detalhe do beneficio Offline"
                      className={styles.btn_td}
                      onClick={() => handleDetalheOffline(offline.resultado)}
                    >
                      <i className="fa-sharp fa-solid fa-magnifying-glass"></i>
                    </button>

                    <button
                      title="Consulta Online PDF"
                      className={styles.btn_td}
                    >
                      {parseInt(offline.statusExtratoOn) === 0 ? (
                        <button
                          title={`[Extrato Online] ${offline.descStatusExtratoOn}`}
                          className={styles.btn_pendente_solicitar}
                          onClick={() => handleExtratoOnlinepdf(offline)}
                        >
                          <i class="fa-sharp fa-solid fa-clock-rotate-left"></i>
                        </button>
                      ) : parseInt(offline.statusExtratoOn) === 1 ||
                        parseInt(offline.statusExtratoOn) === 4 ? (
                        <button
                          title={`[Extrato Online] ${offline.descStatusExtratoOn}`}
                          className={styles.btn_solicitado}
                        >
                          <i className="fa-solid fa-clock"></i>
                        </button>
                      ) : parseInt(offline.statusExtratoOn) === 2 ? (
                        <button
                          title={`[Extrato Online] ${offline.descStatusExtratoOn} - clique para imprimir`}
                          className={styles.btn_imprimir}
                          onClick={() => handleExtratoOnlinepdf(offline)}
                        >
                          <i className="fa-sharp fa-solid fa-file-pdf"></i>
                        </button>
                      ) : parseInt(offline.statusExtratoOn) === 3 ? (
                        <button
                          title={`[Extrato Online] ${offline.descStatusExtratoOn} - Clique para refazer a consulta`}
                          className={styles.btn_erro}
                          onClick={() => {
                            setDialogOnline({
                              message: "Refazer Consulta Extrato Online ? ",
                              visibled: true,
                            })
                            setResultOff(offline)
                            setRefConsulta(true)
                          }}
                        >
                          <i className="fa-solid fa-triangle-exclamation"></i>
                        </button>
                      ) : (
                        ""
                      )}
                    </button>
                    {parseInt(offline.idStatusSolicitacaoIn100) === 0 ? (
                      <button
                        title="Clique aqui para gerar sua In100"
                        className={styles.btn_obter}
                        onClick={() => handleObterRequisicao(offline)}
                      >
                        <i className="fa-sharp fa-solid fa-wand-magic-sparkles"></i>
                      </button>
                    ) : parseInt(offline.idStatusSolicitacaoIn100) === 1 ||
                      parseInt(offline.idStatusSolicitacaoIn100) === 5 ? (
                      <button
                        title={offline.situacao_in100}
                        className={styles.btn_wait}
                      >
                        <i className="fa-solid fa-clock"></i>
                      </button>
                    ) : parseInt(offline.idStatusSolicitacaoIn100) === 2 ? (
                      <button
                        title={offline.situacao_in100}
                        className={styles.btn_td}
                        onClick={() => handlePdf(offline.resultado_in100)}
                      >
                        <i className="fa-sharp fa-solid fa-print"></i>
                      </button>
                    ) : parseInt(offline.idStatusSolicitacaoIn100) === 3 ? (
                      <button
                        title={`${offline.situacao_in100}. \nErro: ${offline.msgErro}`}
                        className={styles.btn_erro}
                        onClick={() => handleAlertDialog(offline)}
                      >
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Paginacao pagination={pagination} onPageChange={handlePageChange} />

      {formRepresentante && (
        <FormModal
          closeForm={() => setFormRepresentante(false)}
          title={"Tipo de consulta"}
          form={
            <div className={styles.containerIn100RepLegal}>
              <fieldset>
                <legend>Selecione um tipo de consulta</legend>

                <div className={styles.containerInputsIn100}>
                  <label>
                    <input
                      type="radio"
                      id="in100"
                      name="in100"
                      checked={selectIn100}
                      value={selectIn100}
                      onClick={() => {
                        setSelectIn100(true)
                        setSelectIn100RepLegal(false)
                        setCampoCpfRepresentante(false)
                        setCpfRepresentante({ cpfR: "" })
                      }}
                    />
                    <span>Consultar IN100</span>
                  </label>

                  <label>
                    <input
                      type="radio"
                      id="in100RepLegal"
                      name="in100"
                      checked={selectIn100RepLegal}
                      value={selectIn100RepLegal}
                      onClick={() => {
                        setSelectIn100RepLegal(true)
                        setSelectIn100(false)
                        setCampoCpfRepresentante(true)
                      }}
                    />
                    <span>Consultar IN100 (Representante Legal)</span>
                  </label>
                </div>
              </fieldset>

              {campoCpfRepresentante && (
                <InputText
                  text="CPF do representante legal"
                  name="cpfR"
                  value={cpfRepresentante.cpfR}
                  handleonChange={e => handleCpf(e)}
                />
              )}

              <Buttons
                text="Consultar"
                onClick={() => {
                  ConsultarIn100()
                }}
              />
            </div>
          }
        />
      )}
      {}
      {modalIn100 && (
        <FormModal
          title="Dados IN100"
          closeForm={() => setModalIn100(false)}
          form={
            <div className={styles.section_data_in100}>
              <Buttons text="Imprimir" onClick={() => handlePdf()} />

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados Pessoais</legend>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Numero Benefício"
                    value={in100.NumeroBeneficio}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="CPF" value={in100.CPF} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Nome" value={in100.Nome} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Data Nascimento"
                    value={in100.DataNascimento}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Idade" value={in100.Idade} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Situação" value={in100.Situacao} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="DIB" value={in100.DIB} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="DDB"
                    value={
                      in100.DDB &&
                      new Date(in100.DDB).toLocaleDateString("pt-br", {
                        timeZone: "UTC",
                      })
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="UF Beneficio" value={in100.UFBeneficio} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Empréstimo Bloqueado"
                    value={in100.EmprestimoBloqueado}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Empréstimo Elegível"
                    value={in100.EmprestimoElegivel}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui Representante Legal"
                    value={in100.PossuiRepresentanteLegal}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Nome Representante Legal"
                    value={in100.NomeRepresentanteLegal}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui Procurador"
                    value={in100.PossuiProcurador}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados bancários</legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Número Agência"
                    value={in100.NumeroAgencia}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Nome Agência" value={in100.NomeAgencia} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Número Conta Corrente"
                    value={in100.NumeroContaCorrente}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Meio Pagamento ID"
                    value={in100.MeioPagamentoID}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>
                  Margens - Qtd Emprestimos Ativos Suspensos :{" "}
                  {in100.QtdEmprestimosAtivosSuspensos}{" "}
                </legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem Consignável"
                    value={in100.MargemConsignavel}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Origem Banco ID"
                    value={in100.OrigemBancoID}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Valor Margem Disponível Cartão Benefício"
                    value={in100.ValorMargemDisponivelCartaoBeneficio}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem Consignável Cartão"
                    value={in100.MargemConsignavelCartao}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Valor Limite Cartão"
                    value={in100.ValorLimiteCartao}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Valor Limite Cartão Benefício"
                    value={in100.ValorLimiteCartaoBeneficio}
                  />
                </div>
              </fieldset>
            </div>
          }
        />
      )}

      {modalOffLine && (
        <FormModal
          title="Dados OffLine"
          closeForm={() => setModalOffline(false)}
          form={
            <div>
              <Buttons
                text="Imprimir"
                onClick={() => handlePdfOffLine(resultOff)}
              />

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados Pessoais</legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Numero Benefício"
                    value={resultOff.Beneficiario.Beneficio}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="CPF"
                    value={cpfMask(resultOff.Beneficiario.CPF)}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Nome" value={resultOff.Beneficiario.Nome} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Sexo"
                    value={
                      resultOff.Beneficiario.Sexo === "F"
                        ? "FEMININO"
                        : "MASCULINO"
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Data Nascimento"
                    value={
                      new Date(
                        resultOff.Beneficiario.DataNascimento,
                      ).toLocaleDateString() +
                      " (" +
                      resultOff.Beneficiario.Idade +
                      " Anos)"
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Situação do Beneficio"
                    value={resultOff.Beneficiario.Situacao}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="RG" value={resultOff.Beneficiario.Rg} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Nome da mãe"
                    value={resultOff.Beneficiario.NomeMae}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="DIB" value={resultOff.Beneficiario.DIB} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="DDB"
                    value={
                      resultOff.Beneficiario.DDB &&
                      new Date(resultOff.Beneficiario.DDB).toLocaleDateString(
                        "pt-br",
                        {
                          timeZone: "UTC",
                        },
                      )
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Espécie"
                    value={resultOff.Beneficiario.DescricaoEspecie}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="UF do benefício"
                    value={resultOff.Beneficiario.UFBeneficio}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Empréstimo Bloqueado"
                    value={
                      parseInt(resultOff.Beneficiario.BloqueadoEmprestimo) === 1
                        ? "Sim"
                        : "Não"
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui Representante Legal?"
                    value={
                      parseInt(resultOff.Beneficiario?.PossuiRepresentante) == 1
                        ? "Sim"
                        : "Não"
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui procurador?"
                    value={resultOff.Beneficiario?.DescricaoPossuiProcurador}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Empréstimo Elegível"
                    value={resultOff.Beneficiario?.EmprestimoElegivel}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Pensão alimentícia?"
                    value={resultOff.Beneficiario?.DescricaoPensaoAlimenticia}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Permite empréstimo?"
                    value={resultOff.Beneficiario?.DescricaoPermiteEmprestimo}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Competência"
                    value={resultOff.Beneficiario?.Competencia}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Salário bruto"
                    value={resultOff.ResumoFinanceiro?.SalarioBruto}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Salário base"
                    value={resultOff.ResumoFinanceiro?.SalarioBase}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Data de atualização"
                    value={resultOff.Beneficiario?.DataAtualizacao}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui Procurador"
                    value={resultOff.Beneficiario?.DescricaoPossuiProcurador}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados Representante Legal</legend>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="CPF"
                    value={resultOff.DadosRepresentante?.CPF}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Nome"
                    value={resultOff.DadosRepresentante?.NomeRepresentanteLegal}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados Bancarios</legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Banco"
                    value={
                      resultOff.DadosBancarios.Banco +
                      " - " +
                      resultOff.DadosBancarios?.DescricaoBanco
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Agência"
                    value={resultOff.DadosBancarios.Agencia}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Número da conta corrente"
                    value={resultOff.DadosBancarios.ContaPagto}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Meio de pagamento"
                    value={resultOff.DadosBancarios.DescMeioPagamento}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Margens</legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem disponivel para empréstimo"
                    value={
                      resultOff.ResumoFinanceiro.MargemDisponivelEmprestimo
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem disponivel RCC"
                    value={resultOff.ResumoFinanceiro.MargemDisponivelRcc}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem disponivel RMC"
                    value={resultOff.ResumoFinanceiro.MargemDisponivelRmc}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.emprestimos_offline}>
                <legend>
                  Empréstimos : {resultOff.ResumoFinanceiro.TotalEmprestimos}
                </legend>

                {
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white-700 uppercase bg-dark-50 dark:text-white">
                      <tr>
                        <th scope="col" class="px-3 py-3">
                          Contrato/Banco
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Desc. Empr.
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Parcela
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Pagas
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Prazo
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Inclusão
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Início desconto
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Fim desconto
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Empréstimo
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Saldo
                        </th>
                        <th scope="col" class="px-3 py-3">
                          Taxa
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        //console.log(resultOff )
                        resultOff.Emprestimos.map((emprest, index) => (
                          <tr key={index}>
                            <td>
                              <div className={styles.col_contratos}>
                                <span>{emprest.Contrato}</span>
                                <span>{emprest.NomeBanco}</span>
                              </div>
                            </td>
                            <td>{emprest?.DescricaoTipoEmprestimo}</td>
                            <td>{emprest.ValorParcela}</td>
                            <td>{emprest?.ParcelasPagas}</td>
                            <td>{emprest.Prazo}</td>
                            <td>{emprest.DataAverbacao}</td>
                            <td>{emprest.InicioDesconto}</td>
                            <td>{emprest.FinalDesconto}</td>
                            <td>{emprest.ValorEmprestimo}</td>
                            <td>{emprest?.Saldo}</td>
                            <td>{emprest.Taxa}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                }
              </fieldset>
            </div>
          }
        />
      )}
    </div>
  )
}
