import { useState } from 'react'
import { Alert } from '../../components/AlertDialog'
import { Buttons } from '../../components/Button'
import { InputCheckBox, InputText } from '../../components/Inputs'
import { Salvar, atualizarApiKey } from '../../functions/fornecedor'
import { IAlert } from '../../interfaces/Alert'
import { Estados } from '../../utils/Estados'
import styles from './Fornecedor.module.css'


export const FormFornecedor = ({fornecedorh})=>{
    
   const [fornecedor, setFornecedor] = useState(fornecedorh)
   const [alert, setAlert] = useState(IAlert)
   
   const handlechange =(e)=>{
       setFornecedor({...fornecedor,[e.target.name]: e.target.name === 'ativo'?e.target.checked: e.target.value })
    }


    const handleSubmit = async (e)=>{
        
        try {            
           const ret =  await Salvar(fornecedor)
            
            setAlert({
                message:ret.data.message,
                visibled:true
            }) 
            
            //setModaForn(false)
 
        } catch (error) {
            console.error(error)
            setAlert({
                message:error.response?.data.message || "Erro ao cadastrar fornecedor",
                visibled:true
            })   
        }
    }

    const handleRefreshApiKey = async (e)=>{
        try {             
            const ret =  await atualizarApiKey(fornecedor)
            
            setFornecedor({...fornecedor, 
                apikey:ret.data.apikey, 
                dataHoraValidacaoApiKey:ret.data.dataHoraValidacaoApiKey
            })

            setAlert({
                message:`Api key atualizada com sucesso: ${ret?.data.apikey}`,
                visibled:true
            }) 
         
        } catch (error) {
            
            setAlert({
                message:error.response?.data.message || "Erro ao atualizar Api Key",
                visibled:true
            })   
        }
    }
  
    return(
        <>
        {alert.visibled && <Alert text={alert.message} onDialog={()=>setAlert({})} />}
        <div className={styles.formCadFornecedor}>
                               
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                <InputCheckBox 
                        text="Ativo"
                        name="ativo"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.ativo}
                      
                />
                
                <div className="grid md:grid-cols-2 md:gap-6">
                    <InputText 
                        text="Descrição"
                        name="descricao"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.descricao}
                    />
                    <InputText 
                        text="CNPJ"
                        name="cnpj"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.cnpj}
                    />
                </div>
                
                <div className="grid md:grid-cols-3 md:gap-6" >
                    <InputText 
                        text="Logradouro"
                        name="logradouro"
                        handleonChange={(e)=>handlechange(e) }
                        value = {fornecedor.logradouro}
                    />
                    <InputText 
                        text="Número"
                        name="numero"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.numero}
                    />
                    <InputText 
                        text="Complemento"
                        name="complemento"
                        handleonChange={(e)=>handlechange(e) }
                        value = {fornecedor.complemento}
                    />
                </div>
            
                <div className="grid md:grid-cols-3 md:gap-6">
                    <InputText 
                        text="Cep"
                        name="cep"
                        handleonChange={(e)=>handlechange(e) }
                        value = {fornecedor.cep}
                    />
                    <InputText 
                        text="Cidade"
                        name="cidade"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.cidade}
                    />

                        <label className="block text-sm font-medium leading-6 text-gray-900">
                        <span>Estado</span>
                        <select
                            
                                name={'estado'}
                                id={'estado'}
                                onChange={(e)=>handlechange(e)}
                                value={fornecedor.estado}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                
                            >
                            <option value="">--Selecione--</option>
                                {
                                    Estados?.map((option, index) => (                                                 
                                        <option 
                                            value={option.sigla} 
                                            key={index}
                                        >                        
                                            {option.descricao }
                                        </option>
                                    ))
                                }

                        </select>

                    </label>
                    
                </div>


                <div className=" border border-gray-900/10 pb-3 p-3 mt-3">
                    <div className="grid md:grid-cols-2 md:gap-6 mb-3  ">
                    <InputText 
                        text="URL da api"
                        name="urlapi"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.urlapi}
                    />
                    <InputText 
                        text="Chave Acesso (APIKEY)"
                        name="apikey"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.apikey}
                       
                    />
                    <InputText 
                        text="Data/Hora atualização ApiKey"
                        name="dataHoraValidacaoApiKey"
                        handleonChange={(e)=>handlechange(e)}
                        value = { new Date(fornecedor.dataHoraValidacaoApiKey).toLocaleString() }
                    />
                        
                    
                    </div>

                    <div className="grid md:grid-cols-3 md:gap-6 mb-3 ">
                    <InputText 
                        text="Usuário API"
                        name="usuarioApi"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.usuarioApi}
                    />
                    <InputText 
                        text="Senha API"
                        name="senhaApi"
                        handleonChange={(e)=>handlechange(e)}
                        value = {fornecedor.senhaApi}
                    />
                    
                    <div className="flex flex-col mt-auto">
                        <Buttons 
                            name="atualizarApiKey"
                            text="Atualizar ApiKey"  
                            onClick={(e)=>handleRefreshApiKey(e)}                                                   
                        />            
                    </div>
                    
                    </div>
                    </div>

                <Buttons 
                    onClick={(e)=>handleSubmit(e)}
                    name="salvar"
                    text="Salvar"                                                     
                />
                </div>
            </div>
                                                
        </div>
        </>
    )
}