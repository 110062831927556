import { api } from "../services/api";

export const listarTodasSolicitacoesIn100 = async(page)=>{  
  
    const options ={
      params:{                      
        page
      }
    }
  
    return await api.get("/api/in100/listar_todos", options )
    
}
  
export const getObterRequisicao = async( paramsSolicIn100 )=>{
  

    api.defaults.headers.tiposervico = paramsSolicIn100.tiposervico;
  
    const options ={
      params:{      
        pCpf : paramsSolicIn100.cpf,
        pNumeroBeneficio : paramsSolicIn100.beneficio,
        idConsultaOffline : paramsSolicIn100.idConsultaOff,
        pCpfRepresentante : paramsSolicIn100.cpfRepresentanteLegal ?? ""
      }
    }
    
    return await api.get("/api/extrato/in100", options )
    
}

export const getStatusIn100 = async()=>{

  return await api.get("/api/status-in100" )
}

export const refazerConsulta = async({id, cpfR="" })=>{
  console.log(id)
  const options ={
      idIn100:id,
      cpfR     
    }
  

  return await api.put("/api/in100/refazer_consulta", options )
  
}

