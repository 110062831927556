import { useEffect, useState } from "react"
import { Alert, AlertDialog } from "../../components/AlertDialog"
import { Buttons } from "../../components/Button"
import { InputText } from "../../components/Inputs"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { MyPDFComponent } from "../../components/PdfExtratoOnline/ReactToPdf"
import {
  ConsultarExtratoOnline,
  getSolicitacoesExtratoPdf,
  RefazerConsultaOnline
} from "../../functions/consulta_online"
import { connectToSocket } from "../../socket"
import styles from "./ExtratoOnline.module.css"

const socket = connectToSocket("extrato/online")
    
export const ExtratoOnline = () => {
  const [numBeneficio, setNumBeneficio] = useState("")
  const [listExtratosPdf, setListExtratosPdf] = useState([])
  const [onAlert, setOnAlert] = useState({})
  const [dialogOnline, setDialogOnline] = useState({})
  const [consulta, setConsulta] = useState({})
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoading, setShowLoading] = useState(false)
  const [number, setNumber] = useState(0)
 
  const [notifiedExtratos, setNotifiedExtratos] = useState([]); 

  useEffect(() => {
    socket.connect();
    socket.on("extrato_online", (data) => {
      console.log("🔄 Atualização Extrato Online:", data);

      if (data) {    
        setListExtratosPdf((prevList) =>
          prevList.map((item) => {
            if (item.id === data.id) {
              return {
                ...item,
                statusConsulta: data.statusConsulta,
                jsonResult: data.jsonResult,
                errorResult: data.errorResult
              };
            }
            return item;
          })
        );
      }
    });

    return () => {
      socket.disconnect("extrato_online");
    };
  }, []); 


  useEffect(() => {  
    const fetchData = async () => {
  
      try {
        setShowLoading(true);
        const response = await getSolicitacoesExtratoPdf(currentPage);  
        console.log(response.data.items)            
        setListExtratosPdf(response.data.items);
        setPagination(response.data.pagination);
      } catch (error) {        
        console.error("Erro ao buscar extratos:", error);
        setOnAlert({
          visibled: true,
          message: error.response?.data.message || "Ocorreu um erro desconhecido ao realizar a consulta",
        });
      } finally {
        setShowLoading(false);
      }
    };

    fetchData();
    
  }, [currentPage,number]);



  const handleNumBeneficio = async () => {
    try {
      setShowLoading(true);
      const retorno = await ConsultarExtratoOnline({ beneficio: numBeneficio });
      
      const dataRetorno = {
        ...retorno.data.extrato,
        idExtrato: retorno.data.extrato.id,
        descricao:listExtratosPdf[0]?.descricao ?? "", 
        name:listExtratosPdf[0]?.name ?? ""
      }
      
      setListExtratosPdf([dataRetorno,...listExtratosPdf]);
      
      setNumBeneficio("");
    } catch (error) {
      console.log(error)
      setOnAlert({
        visibled: true,
        message: error.response?.data.message || "Ocorreu um erro desconhecido",
      });
    } finally {
      setShowLoading(false);
    }
  };
    

  const handlePageChange = newPage => {
    setShowLoading(true)
    setCurrentPage(newPage)
  }

  const handleSearchPdfOnline = async extratoOn => {
    setShowLoading(true)
   
    if ( ! extratoOn.jsonResult ) {
      setShowLoading(false)
      return setOnAlert({
        visibled: true,
        message: "Arquivo não existe no servidor",
      })
    }       
    await MyPDFComponent(extratoOn.jsonResult)
    

    setShowLoading(false)
  }

  const RefazerConsulta = async d => {
    try {
      setShowLoading(true)
      if (d) {
        
        setListExtratosPdf((prevList) =>
          prevList.map((item) =>
            item.id === consulta.id ? { ...item, statusConsulta: 0 } : item
          )
        );
        await RefazerConsultaOnline(consulta.beneficio,consulta.id)
        
      }
      setShowLoading(false)
      setDialogOnline({})
    } catch (error) {
      setDialogOnline({})
      setShowLoading(false)
      setOnAlert({
        visibled: true,
        message: error.response?.data.message || "Ocorreu um erro desconhecido",
      });
    }


  }

  return (
    <div className={styles.container_extratoOnline}>
      { showLoading && <Loading />}
      {onAlert.visibled && (
        <Alert text={onAlert.message} onDialog={() => setOnAlert({})} />
      )}
      {dialogOnline.visibled && (
        <AlertDialog text={dialogOnline.message} onDialog={RefazerConsulta} />
      )}
      <div className={styles.container_inputs}>
        <InputText
          type="number"
          required={true}
          text="Número Beneficio"
          value={numBeneficio}
          handleonChange={e => setNumBeneficio(e.target.value)}
        />
        <div className={styles.btn_buscar}>
          <Buttons text="Buscar" onClick={handleNumBeneficio} />
        </div>
      </div>
      <div className={styles.table_extrato}>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-50 uppercase bg-gray-800 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                Beneficio
              </th>
              <th scope="col" className="p-4">
                Data
              </th>
              <th scope="col" className="p-4">
                Serviço
              </th>
              <th scope="col" className="p-4">
                Usuário
              </th>
              <th scope="col" className="p-4" style={{ textAlign: "center" }}>
                Arquivo
              </th>
            </tr>
          </thead>
          <tbody>
            {listExtratosPdf &&
              listExtratosPdf.map((consulta, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-3 py-2">{consulta.beneficio}</td>
                  <td className="px-3 py-2">
                    {new Date(consulta.createdAt).toLocaleString()}                                        
                  </td>
                  <td className="px-3 py-2">{consulta.descricao}</td>
                  <td className="px-3 py-2">{consulta.name}</td>
                  <td className={styles.tdPdf}>
                    {parseInt(consulta.statusConsulta) === 0 ||
                    parseInt(consulta.statusConsulta) === 1 ||
                    parseInt(consulta.statusConsulta) === 4 ? (
                      <button
                        title={consulta.descStatusServ}
                        className={styles.btn_solicitar}
                      >
                        <i className="fa-solid fa-clock"></i>
                      </button>
                    ) : parseInt(consulta.statusConsulta) === 2 ? (
                      <button
                        title={`${consulta.descStatusServ} clique para imprimir`}
                        className={styles.btn_imprimir}
                        onClick={() => handleSearchPdfOnline(consulta)}
                      >
                        <i
                          className="fa-sharp fa-solid fa-file-pdf"
                          title="Baixar pdf"
                        ></i>
                      </button>
                    ) : parseInt(consulta.statusConsulta) === 3 ? (
                      <button
                        title={`${consulta.errorResult}\nclique para refazer a consulta`}
                        className={styles.btn_erro}
                        onClick={() => {
                          setDialogOnline({
                            visibled: true,
                            message: `${consulta.errorResult}\nDeseja refazer a consulta?`,
                          })
                          setConsulta(consulta)
                        }}
                      >
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <Paginacao pagination={pagination} onPageChange={handlePageChange} />
    </div>
  )
}
