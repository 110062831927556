import React, { createContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { createSession } from "../functions/login"
import { api } from "../services/api"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const queryClient = useQueryClient()

  useEffect(() => {
    const activeUserName = sessionStorage.getItem("activeUser") 

    if (activeUserName) {
      const storedUser = localStorage.getItem(`user_${activeUserName}`)
      
      if (storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser)
          setUser(parsedUser)
                    
          api.defaults.headers.Authorization = `Bearer ${parsedUser.token}`

        } catch (error) {
          console.error("Erro ao recuperar usuário:", error)
          localStorage.removeItem(`user_${activeUserName}`) 
        }
      }
    }

    setLoading(false)
  }, [])

  const loginUser = async (user, password) => {
    try {
      const resp = await createSession(user, password)
      const loggedUser = resp.data      

      localStorage.setItem(`user_${loggedUser.name}`, JSON.stringify(loggedUser)) 
      sessionStorage.setItem("activeUser", loggedUser.name) 
 
      api.defaults.headers.Authorization = `Bearer ${loggedUser.token}`
      setUser(loggedUser)
      navigate("/home")
    } catch (error) {
      console.error(error)
      return {
        message: error?.response?.data?.message || "Ocorreu um erro ao realizar login",
      }
    }
  }
  

  const logout = () => {
    if (user) {
      localStorage.removeItem(`user_${user.name}`)
      //localStorage.removeItem(`token_${user.id}`)
      localStorage.removeItem("activeUser") // Remove a referência ao usuário ativo
    }

    setUser(null)
    delete api.defaults.headers.Authorization
    queryClient.invalidateQueries("repoData")
    navigate("/")
  }
  
  return (
    <AuthContext.Provider
      value={{ authenticated: !!user, user, loading, loginUser, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}
