import { api } from "../services/api";

export const postAddServCliente = async (dados) =>{
  
    return api.post("/credito/add_servico_cli", dados)
  }
  
  export const listarCredito = async (page=1, filtros) =>{  

    
    let options = {
      params:{
        page,
        filtros
      }
    }
    
    return await api.get("/credito/filtrar", options)
  }
  
  export const consultarCredito = async (page,filtro) =>{
  
    let options = {
      params : {
        page,
        dataIni:filtro.dataIni,
        dataFim:filtro.dataFim,
        tiposervico:filtro.tiposervico,
        idusu_cli:filtro.idusu_cli,
      }
    }
  
   return await api.get('/credito/filtrar',options)
  }
  
  export const postCredit = async(credit, transacao)=>{
    //let user = JSON.parse( localStorage.getItem('user') )
    console.log(credit)
    //credit.vendedor = user.id
    credit.tipotransacao = transacao
   
    if(credit.id){
      return await api.put("/credito/atualizar", credit)
    }else{
      return await api.post("/credito/adicionar", credit)
    }
    
  }
  
  export const getListarCreditos = async (page = 1) =>{
    
    const options = {
      params : {
        page,    
      }
    }

    try {
      return await api.get('/credito/listar',options)   
    } catch (error) {
      return error
    }
   
  }

  export const DeleteCredito = async (id)=>{
  
    const options ={
      params : {
        id
      }
    }
    return await api.delete("/credito/deletar", options)
  }
  
  export const consultar_saldo = async (tiposervico) =>{  
  
    let user = JSON.parse( localStorage.getItem('user') )
    
    let options = {
      params:{
        idusu_cli:user.id,
        tiposervico
      }
    }
    
    return await api.get("/credito/consultar", options)
  }