import { io } from 'socket.io-client';


export const connectToSocket = (namespace) =>{
  const API_URL = process.env.REACT_APP_URL_API

  const socket = io(`${API_URL}/${namespace}`, { 
    autoConnect: false,
    transports: ["websocket"],
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 2000
    
  });

  socket.on('connect',()=>{
    console.log(`✅Conectado ao WebSocket /${namespace}`);
  })


  socket.on('disconnect',()=>{
    console.log(`❌ Desconectado ao WebSocket /${namespace}`);
  })

  socket.on('connect_error', (err) => {
    console.error(`⚠️ Erro ao conectar ao WebSocket /${namespace}:`, err.message);
  });

  return socket
}

