import { api } from '../services/api'

export const Listar = async(filtro)=>{
    try {
        const options ={
            params:{      
              filtro
            }
          }
     return await api.get('/fornecedor',options)

    } catch (error) {
        throw error
    }
}

export const Salvar = async(fornecedor) =>{
    try {
        if(!fornecedor.id){           
           return await api.post('/fornecedor', fornecedor)
        } else {         
            return await api.put('/fornecedor', fornecedor)
        }
        

    } catch (error) {
        throw error
    }
}


export const atualizarApiKey = async(fornecedor) =>{
    try {
                
        return await api.put('/atualizar-api-key', fornecedor)        

    } catch (error) {
        throw error
    }
}