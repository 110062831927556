import { useEffect, useState } from "react"
import { ExtratoOfflinePdf } from "../../Reports/Offline"
import { Buttons } from "../../components/Button"
import { FormModal } from "../../components/FormModal"
import { InputText } from "../../components/Inputs"
import { getObterRequisicao, listarTodasSolicitacoesIn100, refazerConsulta } from "../../functions/In100"
import {
  ApiConsultaOffline
} from "../../functions/consulta_offline"
import {
  ConsultarExtratoOnline
} from "../../functions/consulta_online"
import styles from "./ExtratoIn100.module.css"

import { useMutation, useQuery, useQueryClient } from "react-query"
import { ExtratoIn100Pdf } from "../../Reports/In100/ExtratoIn100Pdf"
import { Alert, AlertDialog } from "../../components/AlertDialog"
import { Loading } from "../../components/Loading"
import Paginacao from "../../components/Paginacao"
import { IAlert } from "../../interfaces/Alert"
import { IIn100 } from "../../interfaces/In100"
import { cpfMask } from "../../utils/cpfMask"

export const ExtratoIn100 = () => {
  const [modalIn100, setModalIn100] = useState(false)
  const [modalOffLine, setModalOffline] = useState(false)
  const [alert, setAlert] = useState({ visibled: false, text: "" })
  const [tipoconsulta, setTipoConsulta] = useState(1)
  const [in100, setIn100] = useState(IIn100)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [cpf, setCpf] = useState("")
  const [beneficio, setBeneficio] = useState("")
  const [lista_offline, setLista_Offline] = useState([])
  const [arrayPages, setArrayPages] = useState([])
  const [qPages, setQPages] = useState(0)
  const [resultOff, setResultOff] = useState({})
  const [totalRegistros, setTotalRegistros] = useState(0)
  const [campoCpfRepresentante, setCampoCpfRepresentante] = useState(false)
  const [cpfRepresentante, setCpfRepresentante] = useState({ cpfR: "" })
  const [formRepresentante, setFormRepresentante] = useState(false)

  const [selectIn100, setSelectIn100] = useState(true)
  const [selectIn100RepLegal, setSelectIn100RepLegal] = useState(false)
  const [solicitarIn100, setSolicitarIn100] = useState({
    tiposervico: 4,
    idConsultaOff: 0,
    cpf: "",
    beneficio: "",
    cpfRepresentanteLegal: "",
  })
  const [dialogIn100, setDialogIn100] = useState(IAlert)
  const [dialogOnline, setDialogOnline] = useState(IAlert)
  const [refConsulta, setRefConsulta] = useState(false)
  const queryClient = useQueryClient()
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: 10,
  })
  useEffect(() => {
    setLoading(true)
  }, [])

  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ["in100Data", currentPage],
    async () => {
      const resp = await listarTodasSolicitacoesIn100(currentPage)      
      return resp.data
    },
    {
      retry: 3,
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
      staleTime: 5000,
      onSuccess: data => {        
        setLista_Offline(data.items)
        setPagination(data.pagination)
        setLoading(false)
      },
      onError: error => {
        console.error(error)
        setLoading(false)
      },
    },
  )

  const refactorIn100 = useMutation(refazerConsulta, {
    onSuccess: () => {
      refetch()
      setLoading(false)
      setCpfRepresentante("")
      setResultOff({})
    },
    onError: error => {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response ? error.response?.data.message : "Ocorreu um erro desconhecido",
      })
    },
  })

  const solicIn100 = useMutation(getObterRequisicao, {
    onSuccess: () => {
      refetch()      
    },
    onError: error => {
      console.error(error.response)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response?.data.message || "Ocorreu um erro desconhecido",
      })
    },
  })

  const solicOffline = useMutation(ApiConsultaOffline, {
    onSuccess: () => {
      refetch()      
    },
    onError: error => {
      console.error(error.response)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response
          ? error.response?.data.message
          : "Erro ao realizar consulta",
      })
    },
  })

  const solicOnline = useMutation(ConsultarExtratoOnline, {
    onSuccess: response => {
      refetch()
      setAlert({
        visibled: true,
        text: response.data.message,
      })      
    },
    onError: error => {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: error.response?.data.message || "Ocorreu um erro desconhecido",
      })
    },
  })

 

  if (error) {
    console.error(error?.response)
    return error?.response?.data.message
  }

  const handlePdf = dados => {
    // ComprovanteIn100(data)
    ExtratoIn100Pdf(dados)
  }

  const handlePdfOffLine = dados => {
    ExtratoOfflinePdf(dados)
  }

  const handleDetalheOffline = async dados => {
    setModalOffline(true)
    setResultOff(dados)
  }

  const handleObterRequisicao = async () => {
    setSolicitarIn100({
      idConsultaOff: 0,
      cpf: "",
      beneficio: beneficio,
    })

    setFormRepresentante(true)
  }

  const handleCpf = e => {
    setCpfRepresentante({ cpfR: cpfMask(e.target.value) })
  }

  const handleInputChange = e => {
    setBeneficio(e.target.value) 
  }

  const ConsultarIn100 = async () => {
    if (selectIn100RepLegal && cpfRepresentante.cpfR === "") {
      return setAlert({
        visibled: true,
        text: "Informe o número de CPF do representante legal",
      })
    }

    try {
      const cpfRep = cpfRepresentante.cpfR
        ?.replaceAll(".", "")
        .replaceAll("-", "")

      setSolicitarIn100({ cpfRepresentanteLegal: cpfRep })
      console.log(solicitarIn100)
      if (refConsulta) {
        //await refactorIn100.mutate({ id: resultOff.idIn100, cpfR: cpfRep })
      } else {
        await solicIn100.mutate(solicitarIn100)
      }

      setFormRepresentante(false)
      setLoading(true)
    } catch (error) {
      console.error(error)
      setLoading(false)
      setAlert({
        visibled: true,
        text: `${error.response?.status} | ${error.response?.data.message}`,
      })
    }
  }

  const handleAlertDialog = dados => {
    setResultOff(dados)
    setRefConsulta(true)
    setDialogIn100({ message: "Refazer Consulta da in100 ? ", visibled: true })
  }


  const handlePageChange = newPage => {
    setLoading(true)
    setCurrentPage(newPage)
  }

  return (
    <div className={styles.container_in100}>
      {loading && <Loading />}
      {alert.visibled && (
        <Alert
          text={alert.text}
          onDialog={() => setAlert({ visibled: false, text: "" })}
        />
      )}

      {dialogIn100.visibled && (
        <AlertDialog
          text={dialogIn100.message}
          onDialog={d => {
            if (d) {
              setFormRepresentante(true)
              setDialogIn100(false)
            }
            setDialogIn100({})
          }}
        />
      )}      

      <div className={styles.parametro_pesquisa}>
        <fieldset>
          <legend>Parâmetro de pesquisa</legend>

          <div className={styles.container_inputs}>            

            <div className={styles.inputText_CpfBen}>
              <InputText
                placeholder={"Digite o número do benefício"}
                text="Nº Beneficio"
                handleonChange={e => handleInputChange(e)}
                value={beneficio}
              />
            </div>

            <Buttons text="Pesquisar" onClick={() => handleObterRequisicao()} />
          </div>
        </fieldset>

        {isLoading && <strong>Carregando...</strong>}
      </div>

      <div className={styles.table_in100}>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white-700 uppercase bg-dark-50 dark:text-white">
            <tr>
              <th scope="col" className="px-3 py-3">
                CPF
              </th>
              <th scope="col" className="px-3 py-3">
                Nº do benefício
              </th>
              <th scope="col" className="px-3 py-3">
                Nome do beneficiário
              </th>
              <th scope="col" className="px-3 py-3">
                Espécie
              </th>
              <th scope="col" className="px-3 py-3">
                Data/Hora Solicitação
              </th>
              <th scope="col" className="px-3 py-3">
                Situação
              </th>
              <th scope="col" className={`${styles.acoes} px-3 py-3`}>
                Ações
              </th>
            </tr>
          </thead>
          <tbody>
            {lista_offline &&
              lista_offline.map((offline, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td className="px-3 py-2">{offline.resultado?.cadastro.cpf}</td>
                  <td className="px-3 py-2">{offline.numeroBeneficio}</td>
                  <td className="px-3 py-2">
                    {offline.resultado?.cadastro.nome}
                  </td>
                  <td className="px-3 py-2">
                    {offline.resultado?.cadastro.descricaoEspecie}
                  </td>
                  <td className="px-3 py-2">
                    <div
                      title={`Ultima atualização : ${new Date(
                        offline.updatedAt,
                      ).toLocaleString()}`}
                    >
                      {offline.createdAt != null
                        ? new Date(offline.createdAt).toLocaleString()
                        : ""}
                    </div>
                  </td>

                  <td className="px-3 py-2">{offline.situacao}</td>

                  <td className={styles.acoes}>
                                        
                    {parseInt(offline.idStatusSolicitacaoIn100) === 0 ? (
                      <button
                        title="Clique aqui para gerar sua In100"
                        className={styles.btn_obter}
                        onClick={() => handleObterRequisicao(offline)}
                      >
                        <i className="fa-sharp fa-solid fa-wand-magic-sparkles"></i>
                      </button>
                    ) : parseInt(offline.idStatusSolicitacaoIn100) === 1 ||
                      parseInt(offline.idStatusSolicitacaoIn100) === 5 ? (
                      <button
                        title={offline.situacao_in100}
                        className={styles.btn_wait}
                      >
                        <i className="fa-solid fa-clock"></i>
                      </button>
                    ) : parseInt(offline.idStatusSolicitacaoIn100) === 2 ? (
                      <button
                        title={offline.situacao}
                        className={styles.btn_td}
                        onClick={() => handlePdf(offline.resultado)}
                      >
                        <i className="fa-sharp fa-solid fa-print"></i>
                      </button>
                    ) : parseInt(offline.idStatusSolicitacaoIn100) === 3 ? (
                      <button
                        title={`${offline.situacao}. \nErro: ${offline.msgErro}`}
                        className={styles.btn_erro}
                        onClick={() => handleAlertDialog(offline)}
                      >
                        <i className="fa-solid fa-triangle-exclamation"></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Paginacao pagination={pagination} onPageChange={handlePageChange} />

      {formRepresentante && (
        <FormModal
          closeForm={() => setFormRepresentante(false)}
          title={"Tipo de consulta"}
          form={
            <div className={styles.containerIn100RepLegal}>
              <fieldset>
                <legend>Selecione um tipo de consulta</legend>

                <div className={styles.containerInputsIn100}>
                  <label>
                    <input
                      type="radio"
                      id="in100"
                      name="in100"
                      checked={selectIn100}
                      value={selectIn100}
                      onClick={() => {
                        setSelectIn100(true)
                        setSelectIn100RepLegal(false)
                        setCampoCpfRepresentante(false)
                        setCpfRepresentante({ cpfR: "" })
                      }}
                    />
                    <span>Consultar IN100</span>
                  </label>

                  <label>
                    <input
                      type="radio"
                      id="in100RepLegal"
                      name="in100"
                      checked={selectIn100RepLegal}
                      value={selectIn100RepLegal}
                      onClick={() => {
                        setSelectIn100RepLegal(true)
                        setSelectIn100(false)
                        setCampoCpfRepresentante(true)
                      }}
                    />
                    <span>Consultar IN100 (Representante Legal)</span>
                  </label>
                </div>
              </fieldset>

              {campoCpfRepresentante && (
                <InputText
                  text="CPF do representante legal"
                  name="cpfR"
                  value={cpfRepresentante.cpfR}
                  handleonChange={e => handleCpf(e)}
                />
              )}

              <Buttons
                text="Consultar"
                onClick={() => {
                  ConsultarIn100()
                }}
              />
            </div>
          }
        />
      )}
      {}
      {modalIn100 && (
        <FormModal
          title="Dados IN100"
          closeForm={() => setModalIn100(false)}
          form={
            <div className={styles.section_data_in100}>
              <Buttons text="Imprimir" onClick={() => handlePdf()} />

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados Pessoais</legend>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Numero Benefício"
                    value={in100.NumeroBeneficio}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="CPF" value={in100.CPF} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Nome" value={in100.Nome} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Data Nascimento"
                    value={in100.DataNascimento}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Idade" value={in100.Idade} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Situação" value={in100.Situacao} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="DIB" value={in100.DIB} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="DDB"
                    value={
                      in100.DDB &&
                      new Date(in100.DDB).toLocaleDateString("pt-br", {
                        timeZone: "UTC",
                      })
                    }
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText text="UF Beneficio" value={in100.UFBeneficio} />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Empréstimo Bloqueado"
                    value={in100.EmprestimoBloqueado}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Empréstimo Elegível"
                    value={in100.EmprestimoElegivel}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui Representante Legal"
                    value={in100.PossuiRepresentanteLegal}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Nome Representante Legal"
                    value={in100.NomeRepresentanteLegal}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Possui Procurador"
                    value={in100.PossuiProcurador}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>Dados bancários</legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Número Agência"
                    value={in100.NumeroAgencia}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText text="Nome Agência" value={in100.NomeAgencia} />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Número Conta Corrente"
                    value={in100.NumeroContaCorrente}
                  />
                </div>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Meio Pagamento ID"
                    value={in100.MeioPagamentoID}
                  />
                </div>
              </fieldset>

              <fieldset className={styles.dados_pessoais_offline}>
                <legend>
                  Margens - Qtd Emprestimos Ativos Suspensos :{" "}
                  {in100.QtdEmprestimosAtivosSuspensos}{" "}
                </legend>

                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem Consignável"
                    value={in100.MargemConsignavel}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Origem Banco ID"
                    value={in100.OrigemBancoID}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Valor Margem Disponível Cartão Benefício"
                    value={in100.ValorMargemDisponivelCartaoBeneficio}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Margem Consignável Cartão"
                    value={in100.MargemConsignavelCartao}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Valor Limite Cartão"
                    value={in100.ValorLimiteCartao}
                  />
                </div>
                <div className={styles.inputs_in100}>
                  <InputText
                    text="Valor Limite Cartão Benefício"
                    value={in100.ValorLimiteCartaoBeneficio}
                  />
                </div>
              </fieldset>
            </div>
          }
        />
      )}

    </div>
  )
}
